import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
@Component({
  selector: 'app-product-pickup',
  templateUrl: './product-pickup.component.html',
  styleUrls: ['./product-pickup.component.scss'],
})
export class ProductPickupComponent implements OnInit {

  @ViewChild('cate') cate: ElementRef;
  @ViewChild('space') space: ElementRef;


  @Input() childData: any;

  @Output()
  onUpdate = new EventEmitter<any>();//数据改变通知

  public categoriesList: any[];
  public productList: any[];
  public listIndex: number = 0;
  public basePrice: number = 0;
  public hightGuid: string = '';

  constructor(
    public renderer: Renderer2,
    public UtilService: UtilService
  ) { }

  ngOnInit() {
    let guid = this.childData.item.selected.guid;
    this.hightGuid = guid;
    let initeI = 0;
    for(let i in this.childData.data){
      for(let ii in this.childData.data[i]['list']){
        if(this.childData.data[i]['list'][ii]['guid'] == guid){
          initeI = parseInt(i);
        }
      }
    }
    this.setList(initeI); // 初始列表
  }


  ngAfterViewInit() {
    const myDivExists = this.cate && this.cate.nativeElement;
    if (myDivExists) {
      this.space.nativeElement.style.height = this.cate.nativeElement.offsetHeight + 'px';
      setTimeout(() => {
        this.space.nativeElement.style.height = this.cate.nativeElement.offsetHeight + 'px';
      }, 100
      );
    }
  }
  formatDec(n){
    return this.UtilService.formatDec(n);
  }
  // 显示指定分类列表
  setList(i = 0) {
    this.listIndex = i;
    this.basePrice = this.childData.item.basePrice;

    if (this.childData.type == 'categories') {
      this.categoriesList = this.childData.data;
      let tmplist = this.categoriesList[i]['list'];
      let exclude = this.categoriesList[i]['excludeItems'];
      exclude = exclude.split(';');
      tmplist = tmplist.filter((l) => {
        return !this.UtilService.in_array(l['dishRef'], exclude);
      })
      this.productList = tmplist;
      
      if(this.categoriesList[i]['basePrice']){
        this.basePrice = this.categoriesList[i]['basePrice'];
      }
    } else if (this.childData.type == 'product') {
      this.productList = this.childData.data;
      let price  = this.childData.item.items[i]['price'];
      if(price ){
        this.basePrice = price;
      }
    }
  }
  // 分类筛选
  filterNewList(n, item) {
    this.setList(n);
  }
  // 前往产品配置
  goSetUp(item, i) {
    //向父组件传递事件
    this.onUpdate.emit({ 
      pro: item, 
      cate: this.childData.type == 'categories'?this.categoriesList[this.listIndex]:[],
      type: this.childData.type, 
      item: this.childData.item,
      i: i
    });
  }
   // 图片不存在时的事件
   noPicture(event): void {
    var img = event.srcElement;
    img.src = "./assets/exampleimg/no-picture.png";
    img.onerror = null;// 控制不要一直跳动
  }

   onSwipeRight(e){
    const maxIndex=this.categoriesList.length;
    let listIndex = this.listIndex - 1;
    if( listIndex<=0 ) listIndex = 0;
    console.log(this.listIndex,this.categoriesList,listIndex,'onSwipeLeftonSwipeLeftonSwipeLeft',e)
    this.setList(listIndex)
  }
  onSwipeLeft(e){
    const maxIndex=this.categoriesList.length;
    let listIndex = this.listIndex + 1;
    if( listIndex >= maxIndex ) listIndex = this.listIndex;

    console.log(this.listIndex,this.categoriesList,listIndex,'onSwipeRightonSwipeRightonSwipeRight',e)
    this.setList(listIndex)
  }
  swipe(){
    console.log(55555555555)
  }
}
import { Component, OnInit, AfterViewInit, Input, SimpleChanges, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { HappyHourService } from '../../services/happyHour.service';
import { StorageServic } from '../../services/storage/storage.service';
import * as moment from 'moment';
import { UtilService } from 'src/app/services/util.service';
import { Router  } from '@angular/router';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})

export class CountdownComponent implements OnInit, AfterViewInit {

  private Hours: string = '00';
  private Minutes: string = "00";
  private Seconds: string = "00";

  private prevewServerTime:any; // 上次接口时间
  private nowTimeObj: any; // 运行时钟对象
  private startTimeObj: any; // 开始时钟对象
  private endTimeObj: any; // 结束时钟对象
  private isInited: boolean = false; // 环境初始化状态
  private runing: string = 'waitRun';// ['waitRun', 'running', 'runEnd'];// 运行状态，只有它为running时才会显示计数器
  private happStatu: string = 'stop';// ['run', 'stop2run', 'run2top', 'stop']; // 状态变化

  private isEnabled: boolean; // 是否开启
  private isStarted: boolean; // 是否开始
  private startTime: string;  // 开始时间
  private endTime: string;    // 结束时间
  private serverTime: string; // 服务器时间

  @Input("background")
  background: any = '#ffff';//背景色

  @Input("happyHourStatus")
  happyHourStatus: string;//运行状态
  @Output()
  happyHourStatusChange = new EventEmitter();


  @Output('setHappyEvent')
  setHappyEvent = new EventEmitter()



  constructor(
    private StorageServic: StorageServic,
    private HappyHourService: HappyHourService,
    private router: Router,
    private Util:UtilService,
  ) {
    // 创建组件时调用
  }

  ngOnInit() { }

  time2towchr(n = 0): string {
    const n2 = '0' + n.toString();
    return <string>n2.slice(-2) || n2;
  }


  // 设置倒计时分秒为显示所用
  // 跳动, 页面计时器读数刷新
  setCountDown(sec) {
    var hours = Math.floor(sec / 3600); hours = hours > 0 ? hours : 0;
    var minutes = Math.floor((sec - (hours * 3600)) / 60); minutes = minutes > 0 ? minutes : 0;
    var seconds = sec % 60; seconds = seconds > 0 ? seconds : 0;
    this.Hours = this.time2towchr(hours);
    this.Minutes = this.time2towchr(minutes);
    this.Seconds = this.time2towchr(seconds);
  }
  // 倒计时跳动
  // 1.更新本地时钟，2.增加时钟秒数，3.刷新页面显示
  runTimer(): void {
    if (this.isInited) {
      this.nowTimeObj.add(1, 's');
      const allSeconds = this.endTimeObj.diff(this.nowTimeObj, 'seconds'); //剩余秒数
      this.setCountDown(allSeconds);// 跳动, 页面计时器读数刷新, 只要 这个计时器处于活动区间都跑马
    }
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  // 运行计数器检测动态更新
  async runCountDown() {
    // 获得happy hour数据
    // 异步获取，本次获取的都是缓存的内容
    const o = await this.HappyHourService.getHappyHourSetting();

    try {
      if (!o) {
        throw 'error';
      }
      const nowServerTime =  moment(o.serverTime).utc(); // 服务器时间, 时间校正用，每次都重置这个时间
      let updateNowTime:boolean = false;
      if( !this.prevewServerTime ){ // 上次获得的服务器是将
        this.prevewServerTime =  moment(o.serverTime).utc();
      }
      if( this.prevewServerTime.unix() != nowServerTime.unix()){ // 如果本次时间和上次时间跑偏就重置，因为程序运行可能会卡，导致时间误差
        updateNowTime = true;
        this.prevewServerTime = moment(o.serverTime).utc();
      }
      if (!this.nowTimeObj || updateNowTime) { // 当前时间未设置或者当前时间有偏差时设置当前时间对象
        this.nowTimeObj = moment(o.serverTime).utc();
      }
      if (!this.endTimeObj) { // 如果截止时间未设置则设置它 *
        this.endTimeObj = moment(o.serverTime).utc();
      }
      if(!this.startTimeObj){// 如果活动开始计时时间未设置则设置它 *
        this.startTimeObj = moment(o.serverTime).utc();
      }
      //准备好活动开始时间
      const startTimeArr = o.startTime.split(':');//['04','46'];//['10','24'];// 
      this.startTimeObj.hour(parseInt(startTimeArr[0]));
      this.startTimeObj.minute(parseInt(startTimeArr[1]));
      this.startTimeObj.seconds("00");
      // 准备好活动结束时间
      const endTimeArr = o.endTime.split(':');//['04','46'];//['10','24'];// 
      this.endTimeObj.hour(parseInt(endTimeArr[0]));
      this.endTimeObj.minute(parseInt(endTimeArr[1]));
      this.endTimeObj.seconds("00");
      //初始化完成，不代表活动进行哦，此状态表示后续可以进行操作
      this.isInited = true;
    } catch (error) {
      return false;
    } finally {}
    return o;
  }

  async ngAfterViewInit() {
    // this.Timer();
    // this.Timer(); // 计算剩余时间
    // 每秒计算一次
    // 这里需要10秒请求一次接口，用来适应提前结束的情况，比如手工取消活动
    // 到时间自动取消
    
    // 首次强制更新
    const o = await this.HappyHourService.getHappyHourSetting(true);
    setInterval(()=>{
      // 不在basket和order页则不工作
      if(!this.Util.in_array(this.router.url, ['/tabs/basket', '/tabs/order'] )){
        return;
      }
      // 更新数据
      this.runCountDown().then((a) => {
        //action: running运行中, end 结束
        this.isEnabled  = a.isEnabled;  // 是否开启活动
        this.isStarted  = a.isStarted;  // 是否开始
        this.startTime  = a.startTime;  // 开始时间
        this.endTime    = a.endTime;    // 结束时间
        this.serverTime = a.serverTime; //服务器时间
      }).catch(s=>{});// 初始化计时器

      if (this.isInited) {
        // 跑秒
        this.runTimer();
        // 跑秒完成
        this.happyHourStatusChange.emit(this.happyHourStatus);
        let runSecend = this.nowTimeObj.diff(this.startTimeObj, 'seconds');//　已经进行的秒数
        let leftSecend = this.endTimeObj.diff(this.nowTimeObj, 'seconds');//　活动剩余的秒数
        const happyHourSetting = this.StorageServic.read('happyHourSetting');
        // 控制控件运作状态
        if( runSecend > 0 && leftSecend > 0 && this.isEnabled && this.isStarted){
          this.runing = 'runing'
          // 活动进行中
        }else if( runSecend <=0  && this.isEnabled && this.isStarted ){
          this.runing = 'waitRun'
          // 活动还未开始
        }else{
          this.runing = 'runEnd'
          //活动结束了
        }
        const emit = { now: this.runing, prevew: this.happyHourStatus, items:happyHourSetting.items, t:[runSecend,leftSecend], v:this.nowTimeObj.unix()  };
        console.log( 'this.setHappyEvent.emit', emit )
        this.setHappyEvent.emit(emit);
      }
    }, 1000)
  }

  ngOnDestroy() { }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Storage } from "@ionic/storage";
import { appRestful } from './appResful';
import { LoadingController } from '@ionic/angular';
import { timeout, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class HttpserviceService {
  isLoadingOpen: boolean = false;
  public token = ''
  public httpHeader: any = ''
  public commonUrl = ''
  public timeOut:number = 40000;
  constructor(public http: HttpClient, public storage: Storage, private loadingCtrl: LoadingController) {
    this.commonUrl = appRestful.getProdUrl()
    this.token = appRestful.getAuthorization()
  }

  isFullUrl(url){
    var isFull = false;
    try{
        new URL(url);
        isFull = true;
    }catch(e){
        isFull = false;
    }
    return isFull;
  }
  //封装get请求 需要两个参数
  async httpGet<R>(url, params) {
    let commonUrl = this.commonUrl;
    if(this.isFullUrl(url)){
      commonUrl = '';
    }
    let httpOption = {
      headers: new HttpHeaders({
        'Authorization': "Basic " + this.token,
        'accept': 'text/plain',
      }),
      params: params
    }
    return new Promise((resolve, reject) => {
      this.http.get(commonUrl + url, httpOption).pipe(timeout(this.timeOut)).subscribe((res) => {
        resolve(res)
      }, (err) => {
        reject(err)
      })
    });
  }
  //封装get请求 同步方法
  httpGet2(url: string, params: any) {
    let commonUrl = this.commonUrl
    if(this.isFullUrl(url)){
      commonUrl = '';
    }
    let httpOption = {
      headers: new HttpHeaders({
        'Authorization': "Basic " + this.token,
        'accept': 'text/plain',
      }),
      params: params
    }
    return this.http.get(commonUrl + url, httpOption).pipe(timeout(this.timeOut)).toPromise();
  }
  //POTS请求 需要三个参数 
  async httpPost(url: string, params: any, isText: boolean = false) {
    let commonUrl = this.commonUrl
    if(this.isFullUrl(url)){
      commonUrl = '';
    }
    let httpOption = {
      headers: new HttpHeaders({
        'Authorization': "Basic " + this.token,
        'Content-Type': 'application/json',
        'accept': 'text/plain',
      })
    }
    if (isText) {
      httpOption = Object.assign(httpOption, { responseType: "text" as "json",'withCredentials': true });
    }
    return new Promise((resolve, reject) => {
      this.http.post(commonUrl + url, params, httpOption).pipe(timeout(this.timeOut)).subscribe((res) => {
        resolve(res)
      }, (err) => {
        reject(err)
      })
    })
  }

  //POTS请求 同步方法
  httpPost2(url: string, params: any) {
    let commonUrl = this.commonUrl;
    if(this.isFullUrl(url)){
      commonUrl = '';
    }
    let httpOption = {
      headers: new HttpHeaders({
        'Authorization': "Basic " + this.token,
        'Content-Type': 'application/json',
        'accept': 'text/plain',
      }),
      observe: "response" as 'body', 
    }
    let result = this.http.post(commonUrl + url, params, httpOption).pipe(timeout(this.timeOut)).toPromise();
    result = result.then((e)=>{ 
      return Object.assign(e['body'],{status:e['status']});
    }).catch((ee)=>{
      return ee;
    });
    return result;
  }
  //请求 需要两个参数 
  async httpDelete(url: string) {
    let commonUrl = this.commonUrl;
    if(this.isFullUrl(url)){
      commonUrl = '';
    }
    let httpOption = {
      headers: new HttpHeaders({
        'Authorization': "Basic " + this.token,
        'Content-Type': 'application/json',
        'accept': 'text/plain',
      })
    }
    return new Promise((resolve, reject) => {
      this.http.delete(commonUrl + url, httpOption).pipe(timeout(this.timeOut)).subscribe((res) => {
        resolve(res)
      }, (err) => {
        reject(err)
      })
    })
  }

  /**
 * 统一调用此方法显示loading
 * @param content 显示的内容
 */
  async showLoading(content: string) {
    if (!this.isLoadingOpen) {
      const loading = await this.loadingCtrl.create({
        message: content,
        duration: 20000,
        translucent: false,
        id: 'httpLoading',
        spinner:'bubbles',
        cssClass:"t4-loading",
      });
      this.isLoadingOpen = true;
      await loading.present();
      return loading;
    }
  }
  /**
   * 关闭loading
   */
  async hideLoading(loading?) {
    if (this.isLoadingOpen || loading) {
      if (loading) {
        await loading.dismiss();
      } else {
        await this.loadingCtrl.dismiss(null, null, 'httpLoading');
      }
      this.isLoadingOpen = false;
    }
  }

}
import { Injectable } from '@angular/core';
import { ToastController, NavController, AlertController, LoadingController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})

export class UtilService {
  constructor(
    public NavController:NavController,
    public toastController: ToastController,
    public alertController: AlertController,
    public LoadingController: LoadingController
  ) { }

  // 检测值是否在数组中存在
  in_array(search, array) {
    for (var i in array) { if (array[i] == search) { return true; } } return false;
  }

  // 格式化数值为金额,保留两位小数
  formatDec(n) {
    n = parseFloat(n);
    return Math.round(n * 100) / 100;
  }

  // 通用异步 toast 提示，无回调
  myToast(msg: string = '') {
    this.toastController.create({
      message: msg,
      color: 'favorite',
      mode: 'ios',
      position: 'middle',
      duration: 1000
    }).then(toast => {
      toast.present();
    });
    return;
  }
  // 通用异步 alert 提示,无回调
  myAlert(msg: string = '') {
    const alert = this.alertController.create({
      cssClass: 'my-custom-class',
      mode: "ios",
      message: msg??'',
      buttons: ['OK']
    }).then(alert=>{
      alert.present();
    });
    return;
  }
  /**
   * 统一调用此方法显示loading
   * @param content 显示的内容
   */
  async myLoding(content: string = 'Loading plase....'){
    const loading = await this.LoadingController.create({
      message: content,
      duration: 20000,
      translucent: false,
      id: 'httpLoading',
      spinner:'bubbles',
      cssClass:"t4-loading",
    });
    await loading.present();
    return loading;
  }
}

